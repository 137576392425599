import { graphql, Link } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import Resources from "../components/projects/Resources"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        resources: Array<{
          title: string
          description: string
          url: string
          image: string
          video?: boolean
        }>
        features: Array<{
          title: string
          description: string
        }>
      }
    }
  }
}

// markup
const MicroservicesTemplate = (props: Props) => {
  const resources = props.data.page.frontmatter.resources
  const features = props.data.page.frontmatter.features
  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className={`microservices`}
    >
      <div className="extra-0">
        <img src={`/img/extra/microservices-4.svg`} alt="" />
      </div>
      <div className="container">
        <div className="hero-microservices content py-6">
          <HTMLContent
            content={props.data.page.html}
            className={"markdown links-animate"}
          ></HTMLContent>
          <img
            className="image"
            src={`/img/extra/microservices-5.svg`}
            alt=""
          />
        </div>

        <div className="markdown content">
          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 mr-6">
              <h1 className="is-size-2 has-text-weight-medium m-0 p-0">
                {features[0].title}
              </h1>
            </div>
            <div className="column is-6">
              <HTMLContent
                className="links-animate"
                content={features[0].description}
              ></HTMLContent>
              <div className="pt-5">
                <Link className="button is-spring" to={`/quickstart`}>
                  TRY OUR QUICKSTART GUIDE
                </Link>
              </div>
            </div>
          </article>
          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 mr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[1].title}
              </h1>
            </div>
            <div className="column is-6">
              <HTMLContent
                className="links-animate"
                content={features[1].description}
              ></HTMLContent>
              <div className="pt-5 mt-3">
                <img
                  className="img-light"
                  src={`/img/extra/microservices-6.svg`}
                  alt=""
                />
                <img
                  className="img-dark"
                  src={`/img/extra/microservices-6-dark.svg`}
                  alt=""
                />
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 mr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[2].title}
              </h1>
            </div>
            <div className="column is-6">
              <HTMLContent
                className="links-animate"
                content={features[2].description}
              ></HTMLContent>
              <div className="pt-5 links-animate">
                <a href="https://content.pivotal.io/practitioners/getting-started-with-spring-cloud-stream">
                  Get started with Spring Cloud Stream
                </a>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 mr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[3].title}
              </h1>
            </div>
            <div className="column is-2">
              <img
                src={`/img/icons/microservices-manage.svg`}
                alt=""
                className="img-light"
                width={160}
              />
              <img
                src={`/img/icons/microservices-manage-dark.svg`}
                alt=""
                className="img-dark"
                width={160}
              />
            </div>
            <div className="column is-5">
              <HTMLContent
                className="links-animate"
                content={features[3].description}
              ></HTMLContent>
              <div className="pt-5 links-animate">
                <a href="https://docs.spring.io/spring-boot/docs/current/reference/htmlsingle/#production-ready-metrics">
                  Get started with Micrometer on Spring Boot
                </a>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6 mb-6">
            <div className="column is-4 mr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[4].title}
              </h1>
            </div>
            <div className="column is-4">
              <HTMLContent
                className="links-animate"
                content={features[4].description}
              ></HTMLContent>
            </div>
            <div className="column is-3 pl-5">
              <img className="img-light" src={`/img/logos/cloud-foundry.svg`} alt="" width={340} />
              <img className="img-dark" src={`/img/logos/cloud-foundry-dark.svg`} alt="" width={340} />
            </div>
          </article>
        </div>

        <div className="has-background-light mt-6 p-6 has-text-centered">
          <div className="py-5">
            <h2 className="is-size-2 is-size-3-mobile has-text-weight-medium pb-6">
              Ready to get started?
            </h2>
            <Link className="button is-spring" to={`/quickstart`}>
              TRY THIS TUTORIAL
            </Link>
          </div>
        </div>

        <Resources resources={resources} />
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default MicroservicesTemplate

// graphQL queries
export const pageQuery = graphql`
  query MicroservicesTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        defaultClass
        resources {
          title
          description
          image
          url
          video
        }
        features {
          title
          description
        }
      }
    }
  }
`
